.tag {
	border: 2px solid #b7b7b7;
	color: #b7b7b7;
	padding: 3px 12px;
	border-radius: 12px;
	cursor: pointer;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.color-tag {
	width: 14px;
	height: 14px;
	display: block;
	border-radius: 3px;
	cursor: pointer;
	border: 1px solid #bababa;
}

.color-tag.active {
	border: 2px solid var(--black);
}

.tag.active {
	border: 2px solid var(--black);
	color: var(--black);
	font-weight: 700;
}

.product-tag-container {
	margin-top: 15px;
	margin-left: 15px !important;
	padding-left: 0px !important;
	border-bottom: 1px solid #dbdbdb;
	padding-bottom: 15px;
}

.display-none {
	display: none;
}

.product-tag-container.print {
	margin-top: 5px;
	margin-left: 15px !important;
	padding-left: 0px !important;
	border-bottom: 1px solid #dbdbdb;
	padding-bottom: 5px;
}

.product-tag {
	border: 2px solid var(--dark-grey);
	color: #000000;
	font-weight: 400;
	padding: 3px 12px;
	border-radius: 12px;
	cursor: pointer;
	text-align: center;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.product-tags {
	display: flex;
	flex-wrap: wrap;
	gap: 10px 15px;
}

.other-tags {
	font-size: var(--text-large);
	margin-top: 60px;
	border-bottom: 1px solid var(--dark-grey);
	padding-bottom: 15px;
	font-weight: 700;
}

.other-tags.print {
	margin-top: 30px;
	padding-bottom: 5px;
	margin-bottom: 5px;
}

.product-page-tag {
	border: 1px solid #525252;
	color: #525252;
	font-weight: 400;
	padding: 3px 12px;
	border-radius: 12px;
	cursor: pointer;
	text-align: center;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	margin-right: 20px;
}

.product-page-tag.active {
	border: 1px solid var(--black);
	color: var(--black);
	font-weight: 700;
}

.product-tag-type {
	font-size: var(--text-small);
	font-weight: 700;
	text-transform: uppercase;
}

.filter-tag {
	border: 2px solid var(--dark-grey);
	color: #000000;
	font-weight: 400;
	padding: 3px 12px;
	border-radius: 12px;
	text-align: center;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	margin-right: 10px;
	position: relative;
	padding-right: 15px;
}
