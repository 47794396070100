#login-background {
	position: absolute;
	top: 90px;
	left: 0;
	width: 100%;
	/* height: 100%; */
	height: 900px;
	object-fit: cover;
}

.login-form-column {
	background-color: var(--light-grey-69);
	min-height: 900px !important;
	padding: 84px !important;
	padding-top: 180px !important;
}

.login-form {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	height: 380px;
}

.signup-form {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	height: 600px !important;
}

.login-container {
	overflow: hidden;
	margin-top: -15px;
}

.login-error {
	color: red;
	font-size: var(--text-medium);
}
