.input-select > select {
	padding: 12px;
	background-color: var(--grey);
	border: none;
	width: 100%;
	font-size: var(--text-medium);
	border-right: 12px solid transparent;
	border-radius: 12px;
}

.input-select > select:focus {
	outline: none;
}

.input-select > select > option:first-child {
	display: none;
}
