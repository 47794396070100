.main-filter {
	display: flex;
	justify-content: center;
	gap: 35px;
	margin: 16px;
}

.main-filter-item {
	display: flex;
	flex-direction: column;
	cursor: pointer;
	padding-bottom: 3px;
}

.main-filter-item > img {
	width: 45px;
	margin: auto;
	margin-bottom: 10px;
}

.main-filter-item > span {
	margin: 0 14px 8px 14px;
}

.main-filter-item.active {
	border-bottom: 3px solid var(--dark-heavy-grey);
	padding-bottom: 0px;
}

.main-filter-item.active > span {
	font-weight: 700;
}

.tags-filter {
	display: flex;
	justify-content: center;
	gap: 8px;
	margin: 6px;
}

.filter-type {
	margin-bottom: 20px;
	font-weight: 500;
	cursor: pointer;
}

.filter-type > span {
	padding-bottom: 5px;
	text-transform: uppercase;
}

.filter-type.active > span {
	border-bottom: 2px solid black;
	font-weight: 700;
}
