.page-header {
  background-color: rgba(249, 249, 249, 0.7);
  padding: 20px;
  padding-bottom: 0px;
}

.page-content {
  background-color: rgba(249, 249, 249, 0.7);
  min-height: 70Vh;
  padding: 22px;
}

.page-title {
  font-size: var(--text-large);
  font-weight: 700;;
}


