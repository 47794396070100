.input-text > input {
	padding: 12px;
	background-color: var(--grey);
	border: none;
	width: 100%;
	font-size: var(--text-medium);
	border-radius: 12px;
}

.input-text > input::placeholder {
	color: var(--black);
	font-style: italic;
}

.input-text > input:focus {
	outline: none;
}
