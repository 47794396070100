.navbar {
	padding: 24px;
	border-bottom-color: #707070;
	border-bottom-width: 1px;
	border-bottom-style: solid;
}

#main-logo {
	width: 160px;
	cursor: pointer;
}

#secondary-logo {
	width: 125px;
	cursor: pointer;
}

.menu-icon-container {
	width: 100%;
	text-align: right;
}

.menu-icon {
	min-width: 24px;
	cursor: pointer;
	margin-left: 24px;
	vertical-align: middle;
	display: inline-block;
	text-align: center;
}

.menu-icon > img {
	width: 24px;
}

.menu-icon > span {
	display: block;
}
