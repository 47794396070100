h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}

.text-extra-small {
  font-size: var(--text-extra-small);
}

.text-small {
  font-size: var(--text-small);
}

.text-medium {
  font-size: var(--text-medium);
}

.text-large {
  font-size: var(--text-large);
}

.text-extra-large {
  font-size: var(--text-extra-large);
}

.bold {
  font-weight: 700;
}
