.btn {
	width: 100%;
	font-size: var(--text-medium);
	padding: 12px;
	border-radius: 0px;
	border: 0px;
	cursor: pointer;
}

.btn-full {
	width: 100% !important;
}

.btn-empty {
	width: auto;
	border: 2px solid var(--dark-grey);
	background-color: var(--dark-grey);
	color: white !important;
	font-weight: 700;
	font-size: var(--text-medium);
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 8px;
	padding-bottom: 8px;
	border-radius: 12px;
}
