@import "./input-text.css";
@import "./input-select.css";

::-webkit-file-upload-button {
	display: none;
}

::file-selector-button {
	display: none;
}

.product-modal-image-add {
	display: inline-block;
	height: 95px;
	width: 100%;
	margin: auto;
	text-align: center;
	padding: 88px 0 0 0;
	overflow: hidden;
	font-weight: 700;
	font-size: 30px;
	border: 1px solid var(--light-grey);

	background: url("https://cdn1.iconfinder.com/data/icons/hawcons/32/698394-icon-130-cloud-upload-512.png")
		center center no-repeat #e4e4e4;
	background-size: 40px 40px;
	border-radius: 5px;
	cursor: pointer;
}

.files-number {
	position: absolute;
	left: 40px;
	bottom: 5px;
	font-size: var(--text-small);
}

input.error {
	border: 1px solid red;
}

input.error::placeholder {
	color: red;
}
