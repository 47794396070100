.loan-title {
	font-weight: 700;
	font-size: var(--text-large);
	text-transform: uppercase;
	display: block;
	border-bottom: 1px solid black;
	padding-bottom: 10px;
}

.loan-details {
	margin-top: 30px;
	font-size: var(--text-small);
}
