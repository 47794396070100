.pagination {
	display: inline-block;
}

.pagination a {
	color: #333333;
	float: left;
	padding: 6px 18px;
	text-decoration: none;
	font-size: var(--text-small);
	margin: 0;
	cursor: pointer;
	margin-left: 3px;
	border-radius: 3px;
}

.pagination a.active {
	background-color: #5d5d5d;
	color: white;
}

.pagination a:hover:not(.active) {
	background-color: #ddd;
}

@media print {
	.pagebreak {
		page-break-before: always;
	} /* page-break-after works, as well */
}
